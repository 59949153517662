<template>
  <div>
    <!-- 新建公告弹窗 -->
    <add-form v-if="addFormVisible" ref="addForm" @refreshDataList="loadDeclareeData" />
    <!-- 编辑公告弹窗 -->
    <update-form v-if="updateFormVisible" ref="updateForm" @refreshDataList="loadDeclareeData" />
    <!-- 编辑计划周期弹窗 -->
    <update-plantime-form v-if="updatePlaneTimeFormVisible" ref="updatePlanetimeForm" @refreshDataList="getProjectProfile"></update-plantime-form>
    <!-- 修改状态弹窗 -->
    <status-form v-if="updateStatusFormVisible" ref="statusForm" @refreshDataList="getProjectProfile"></status-form>
    <!-- 预警任务列表弹窗 -->
    <warning-task-list v-if="warningTaskListVisible" ref="warningTaskList"  />
    <!-- 预警阶段列表弹窗 -->
    <warning-plan-list v-if="warningPlanListVisible" ref="warningPlanList"  />

    <a-row :gutter="12">
      <a-col :span="7">
        <a-card :style="{ height: '510px' }" title="项目信息" :bordered="false" >
          <div slot="extra">
              <span v-if="status === 0">待开始</span>
              <span v-if="status === 1">进行中</span>
              <span v-if="status === 2">已完成</span>
              <div style="display:inline-block">
              <a-tooltip placement="bottom" title="修改状态" style="margin-left:5px" v-permit:updateProjectStatus="{projectId:$route.query.projectId}">
                <a-icon type="edit" @click="updateStatusEvt()" />
              </a-tooltip>
              </div>
          </div>
          <a-card-meta style="margin-top: 20px" :title="masterName" description="项目负责人" >
            <a-avatar slot="avatar" :src="masterAvatar" />
          </a-card-meta>
          <div class="antd-meta-flex" style="margin-top:40px">
            <a-card-meta :title="unStartTaskNums" description="待开始" @click="goTask" style="cursor:pointer;">
              <a-icon style="color: #f5222d" type="stop" slot="avatar" />
            </a-card-meta>
            <a-card-meta :title="processingTaskNums" description="进行中" @click="goTask" style="cursor:pointer;">
              <a-icon
                style="color: #fa8c16"
                type="clock-circle"
                slot="avatar"
              />
            </a-card-meta>
            <a-card-meta :title="finishedTaskNums" description="已完成" @click="goTask" style="cursor:pointer;">
              <a-icon
                style="color: #52c41a"
                type="check-circle"
                slot="avatar"
              />
            </a-card-meta>
          </div>
          <a-card-meta
            style="margin-top: 50px;position:relative;"
            title="项目计划周期"
            :description="planTime"
          >
            <a-icon type="schedule" style="font-size: 24px" slot="avatar" />
          </a-card-meta>
          <div style="display:inline-block" v-permit:changePlanTime="{projectId:$route.query.projectId}">
            <a-tooltip placement="bottom" title="修改时间">
              <a-icon type="edit" @click="updateTimeEvt()" style="margin-left:50px;position:absolute;left:119px;bottom:214px;font-size:16px" />
            </a-tooltip>
          </div>

          <a-card-meta title="项目进度" style="margin-top:50px">
            <a-icon type="hourglass" style="font-size: 24px" slot="avatar" />
            
            <a-progress
              style="width:90%"
              :percent="completeRate"
              status="active"
              slot="description"
            />
          </a-card-meta>
        </a-card>
      </a-col>
      <a-col :span="10">
        <a-card
          title="预警任务"
          class="antd-overview-col-table"
          :bordered="false"
          style="height: 255px"
        >
          <a-button type="link" slot="extra" @click="warningTaskListEvt">更多>></a-button>
          <s-table
            size="small"
            :columns="taskColumns"
            rowKey="id"
            :data="loadTaskData"
          >
            <a type="link" slot="taskTitle" slot-scope="text,record" @click="taskDetailEvt(record)" style="display:block;width:200px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{ record.title }}
            </a>
            <span slot="level" slot-scope="text, record">
              <a-tag color="red" v-if="record.level == 1">最高</a-tag>
              <a-tag color="orange" v-if="record.level == 2">较高</a-tag>
              <a-tag color="blue" v-if="record.level == 3">普通</a-tag>
              <a-tag color="blue" v-if="record.level == 4">较低</a-tag>
              <a-tag color="blue" v-if="record.level == 5">最低</a-tag>
            </span>
          </s-table>
        </a-card>
        <a-card
          title="预警阶段"
          class="antd-overview-col-table"
          :bordered="false"
          style="min-height: 255px"
        >
          <a-button type="link" slot="extra" @click="warningPlanListEvt">更多>></a-button>
          <s-table
            size="small"
            :columns="planColumns"
            rowKey="id"
            :data="loadPlanData"
          >
            <a type="link" slot="planName" slot-scope="text,record" @click="planDetailEvt(record)" style="display:block;width:200px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{ record.planName }}
            </a>
          </s-table>
        </a-card>
      </a-col>
      <a-col :span="7">
        <a-card
          title="交付物提交数量"
          :bordered="false"
          :style="{ height: '510px', padding: '0 0' }"
        >
          共{{ deliverableCount }}项
          <ve-pie
            :data="chartData_deliverable"
            :toolbox="deliverableToolbox"
          ></ve-pie>
        </a-card>
      </a-col>
    </a-row>
    <div :style="{ padding: '80px 0 0px 0' }">
      <a-steps labelPlacement="vertical" :current="reviewIndex">
        <a-step title="评审点">
          <div class="antd-step-icon" slot="icon"></div>
        </a-step>

        <template v-for="review in reviewList">
          <a-step
            :description="$moment(review.endTime).format('YYYY年MM月DD日')"
            :key="review.id"
          >
            <div class="antd-step-icon" slot="icon"></div>
            <div slot="title" class="antd-step-title">
              {{ review.planName }}
            </div>
            <div slot="subTitle">
              状态：
              <span v-if="review.status == 1">已完成</span>
              <span v-if="review.status == 0">未完成</span>
            </div>
          </a-step>
        </template>
      </a-steps>
    </div>
    <a-row :gutter="12">
      <a-col :span="12">
        <a-card title="数据汇总" style="height: 450px;margin-top:30px;width:100%">
          <ve-histogram
            :data="chartData_summary"
            :extend="extend_bar"
            judge-width
          ></ve-histogram>
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card
          title="项目公告"
          class="m-card"
          style="width: 100%; height: 450px;margin-top:30px"
        >
          <a-button class="btn-add" type="primary" @click="createNoticeFormEvt" v-permit:addProjectNotice="{projectId:$route.query.projectId}">新建公告</a-button>
           <a-list
            size="small"
            rowKey="id"
            :data-source = "loadNoticeData"
            :pagination="pagination"
          >
            <a-list-item slot="renderItem" slot-scope="item">
                <div class="m-card-content" style="position:relative;">
                  <a-icon type="clock-circle" style="font-size:25px;position:absolute;top:6px;left:0;color:#1890ff;" />
                  <div class="m-card-top" style="display:inline-block;margin-left:35px;">
                    <span>{{item.content}}</span>
                    <div style="display:inline-block">
                      <a-tooltip placement="bottom" title="修改" v-permit:updateProjectNotice="{projectId:$route.query.projectId}">
                        <a-icon type="edit" @click="updateNoticeEvt(item)" style="margin-left:5px;color:#1890ff" />
                      </a-tooltip>
                       <a-tooltip placement="bottom" title="删除" v-permit:deleteProjectNotice="{projectId:$route.query.projectId}">
                        <a-icon type="minus-circle-o" @click="deleteNoticeEvt(item)" style="margin-left:5px;color:#1890ff;" />
                       </a-tooltip>
                    </div>
                  </div>
                  <div class="m-card-bot" style="margin-left:35px">
                    {{item.createTime}}
                  </div>
                </div>
            </a-list-item>
          </a-list>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import "echarts/lib/component/toolbox";
import AddForm from './moudles/overviewAdd'
import UpdateForm from './moudles/overviewUpdate'
import UpdatePlantimeForm from './moudles/planTimeUpdate'
import StatusForm from "@/components/Status";
import warningTaskList from "./moudles/warningTaskList.vue";
import warningPlanList from "./moudles/warningPlanList.vue";
import {
  projectProfile,
  getProjectOverviewTask,
  getProjectOverviewPlan,
  getOverviewNotice,
  delOverviewNotice
} from "@/httpsAPI/project/overview";

const taskColumns = [
  {
    title: "名称",
    dataIndex: "title",
    scopedSlots: { customRender: "taskTitle" },
  },
  {
    title: "预警时间",
    dataIndex: "endTime",
    width:120,
  },
  {
    title: "优先级",
    dataIndex: "level",
    scopedSlots: { customRender: "level" },
    width:70,
  },
  {
    title: "负责人",
    dataIndex: "masterName",
    width:70,
  },
];

const planColumns = [
  {
    title: "名称",
    dataIndex: "planName",
    scopedSlots: { customRender: "planName" },
    width:200
  },
  {
    title: "预警时间",
    dataIndex: "endTime",
  },
  {
    title: "负责人",
    dataIndex: "masterName",
  },
];
const taskData = {
  pageSize: 10,
  pageNo: 1,
  totalCount: 3,
  totalPage: 1,
  data: {},
};
const planData = {
  pageSize: 10,
  pageNo: 1,
  totalCount: 3,
  totalPage: 1,
  data: {},
};
export default {
  name: "overview",
  data() {
    this.extend_bar = {
      color: ["#f5222d", "#fa8c16", "#52c41a"],
      series: {
        type: "bar",
        label: { show: true, position: "top" },
      },
    };
    return {
      updateFormVisible:false,
      updateStatusFormVisible:false,
      addFormVisible:false,
      updatePlaneTimeFormVisible:false,
      warningTaskListVisible: false,
      warningPlanListVisible: false,
      endTime:'',
      startTime:'',
      taskColumns,
      planColumns,
      status: 0,
      statusId:'',
      masterName: "",
      masterAvatar: "",
      unStartTaskNums: "",
      processingTaskNums: "",
      finishedTaskNums: "",
      planTime: "",
      pageNum: 1,
      completeRate: 0,
      profile: {},
      deliverableCount: 0,
      chartData_deliverable: {
        columns: ["行业", "访问来源"],
        rows: [],
      },
      deliverableToolbox: {},
      reviewList: [],
      reviewIndex: 0,
      summaryList: [],
      chartData_summary: {
        columns: ["工作项", "待开始", "进行中", "已完成"],
        rows: [],
      },
      loadNoticeData:[],
      pagination: {
        total: 0,
        current: 1,
        onChange: page => {
          this.pagination.current = page
          this.loadDeclareeData()
        },
        pageSize: 4,
      },
      loadTaskData: (parameter) => {
        const taskParam = {
          isAlarm: 1,
          taskType: 2,
          projectId: this.$route.query.projectId,
          pageSize: 3,
        };
        return getProjectOverviewTask(taskParam).then((res) => {
          taskData.data = res.data.data;
          return taskData;
        });
      },
      loadPlanData: (parameter) => {
        const planParam = {
          isAlarm: 1,
          planType: 1,
          projectId: this.$route.query.projectId,
          pageSize: 3,
        };
        return getProjectOverviewPlan(planParam).then((res) => {
          planData.data = res.data.data;
          return planData;
        });
      },
    };
  },
  components: {
    AddForm,
    UpdateForm,
    UpdatePlantimeForm,
    StatusForm,
    warningTaskList,
    warningPlanList
  },
  methods: {
    goTask(){
      this.$router.push({path:"/projectManage/task",query: {projectId: this.$route.query.projectId}})
    },
    //修改项目状态
    updateStatusEvt() {
      this.updateStatusFormVisible = true
      this.$nextTick(() => {
        this.$refs.statusForm.init(this.statusId,2,this.status)
      })
    },
    // 任务详情
    taskDetailEvt(record){
      this.$router.push({ path: '/projectManage/task/detail', query: {projectId: this.$route.query.projectId, taskId: record.id} })
    },
    // 阶段详情
    planDetailEvt(record){
      this.$router.push({ path: '/projectManage/stage/detail', query: {projectId: this.$route.query.projectId, planId: record.id} })
    },
    // 预警任务列表
    warningTaskListEvt(){
      this.warningTaskListVisible = true
      this.$nextTick(() => {
        this.$refs.warningTaskList.init(this.$route.query.projectId)
      })
    },
    // 预警阶段列表
    warningPlanListEvt(){
      this.warningPlanListVisible = true
      this.$nextTick(() => {
        this.$refs.warningPlanList.init(this.$route.query.projectId)
      })
    },
    //公告列表
     loadDeclareeData () {
      return getOverviewNotice(Object.assign({ pageNo: this.pagination.current, pageSize: this.pagination.pageSize, projectId: this.$route.query.projectId}, this.queryParams)).then(res => {
        this.loadNoticeData = res.data.data
        this.pagination.total = res.data.totalCount
      })
    },
    // 新增公告
    createNoticeFormEvt () {
      this.addFormVisible = true
      this.$nextTick(() => {
        this.$refs.addForm.init(this.$route.query.projectId)
      })
    },
     // 修改公告
    updateNoticeEvt (item) {
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.updateForm.init(item)
      })
    },
     // 修改项目周期
    updateTimeEvt () {
      this.updatePlaneTimeFormVisible = true
      this.$nextTick(() => {
        this.$refs.updatePlanetimeForm.init(this.$route.query.projectId,this.startTime,this.endTime)
      })
    },
    // 删除公告
    deleteNoticeEvt(item) {
        const that = this
        this.$confirm({
            title: '系统提示',
            content: '确认删除此公告吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                delOverviewNotice(item.id).then(res => {
                    that.$message.success({
                        content: '操作成功',
                        duration: 1,
                        onClose: () => {
                            that.loadDeclareeData()
                        }
                    })
                }).catch(error => {
                    console.log(error)
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    },
    // 查询项目基本信息
    getProjectProfile() {
      var that = this;
      projectProfile({ id: this.$route.query.projectId }).then((res) => {
        that.startTime = res.data.projectProfileVo.startDate
        that.endTime = res.data.projectProfileVo.endDate
        that.profile = res.data;
        that.status = res.data.projectProfileVo.status
        that.statusId = res.data.projectProfileVo.id
        that.masterName = res.data.projectProfileVo.masterName;
        that.masterAvatar = res.data.projectProfileVo.masterAvatar ? (that.$imgBaseUrl + res.data.projectProfileVo.masterAvatar) : "";
        that.unStartTaskNums = res.data.projectProfileVo.unStartTaskNums + "";
        that.processingTaskNums = res.data.projectProfileVo.processingTaskNums + "";
        that.finishedTaskNums = res.data.projectProfileVo.finishedTaskNums + "";
        that.planTime = res.data.projectProfileVo.startTime != null  ? res.data.projectProfileVo.startTime +  " - " + res.data.projectProfileVo.endTime : "开始日期 - 结束日期";
        that.completeRate = res.data.projectProfileVo.completeRate;
        that.deliverableCount = res.data.deliverableSummaryVo ? res.data.deliverableSummaryVo.totalCount : 0;
        that.reviewList = that.profile.reviewList;

        // 遍历当前评审点执行到达节点
        for (let i = 0, len = that.reviewList.length; i < len; i++) {
          if (that.reviewList[i].status == 1) {
            that.reviewIndex = i + 1;
          }
        }

        // 工作任务统计图数据
        this.chartData_summary.rows = [
          {
            工作项: "工作项",
            待开始: that.profile.taskSummaryVo.unstartCount,
            进行中: that.profile.taskSummaryVo.processingCount,
            已完成: that.profile.taskSummaryVo.finishCount,
          },
          {
            工作项: "需求",
            待开始: that.profile.requirementSummaryVo.unstartCount,
            进行中: that.profile.requirementSummaryVo.processingCount,
            已完成: that.profile.requirementSummaryVo.finishCount,
          },
          {
            工作项: "求助单",
            待开始: that.profile.worderSummaryVo.unstartCount,
            进行中: that.profile.worderSummaryVo.processingCount,
            已完成: that.profile.worderSummaryVo.finishCount,
          },
        ];

        console.log(this.chartData_summary.rows)

        that.deliverableToolbox = {
          feature: {
            saveAsImage: {},
          },
        };
        that.chartData_deliverable = {
          columns: ["name", "num"],
          rows:
            that.deliverableCount == 0
              ? []
              : [
                  {
                    name: "未提交",
                    num: res.data.deliverableSummaryVo.uncommitCount,
                  },
                  {
                    name: "已提交",
                    num: res.data.deliverableSummaryVo.commitedCount,
                  },
                ],
        };
      });
    },
  },
  created() {
    this.getProjectProfile();
    this.loadDeclareeData()
  },
};
</script>

<style lang="less">
.ant-card-head-wrapper{
  height: 56px;
}
.antd-meta-flex {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .ant-card-meta {
    flex: 1;
    display: flex;
    align-items: center;
    .ant-card-meta-avatar {
      .anticon {
        font-size: 22px;
      }
    }
    .ant-card-meta-detail {
      overflow: auto;
      .ant-card-meta-title {
        margin-bottom: 0;
      }
    }
  }
}
.antd-overview-col-table {
  .ant-card-body {
    padding: 0;
  }
}
.m-card {
  display: flex;
  flex-direction: column;
  position: relative;
  .btn-add {
    position: absolute;
    top: 12px;
    right: 15px;
  }
  .m-card-content {
    margin-top: 2px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    .m-card-top {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .m-card-bot {
      font-size: 12px;
    }
    .m-name {
      color: #d9001b;
    }
  }
}
.ant-list-split .ant-list-item {
  border: none;
}
.ant-list-something-after-last-item
  .ant-spin-container
  > .ant-list-items
  > .ant-list-item:last-child {
  border: none;
}
.antd-step-title{
    height: 26px;
    line-height: 26px;
    font-size: 12px;
    // border-radius: 13px;
    min-width: 168px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid #6eadf0;
    position: absolute;
    top: -80px;
    left: -55px;
}
.ant-table-placeholder{
  padding: 0 16px;
  border-radius: 0; 
}
</style>
