import API from "@/httpsAPI/API";
import request from "@/utils/request";

// 项目概览预警任务
export function getProjectOverviewTask(params) {
  return request({
    url:API.projectOverviewTask,
    method:'post',
    data:params
  })
}

// 项目概览预警计划
export function getProjectOverviewPlan(params) {
  return request({
    url:API.projectOverviewPlan,
    method:'post',
    data:params
  })
}

// 项目概览基本信息查询
export function projectProfile(params){
  return request({
    url:API.projectProfile + '/' + params.id,
    method:'post',
    data:params
  })
}

// 项目公告查询
export function getOverviewNotice(params){
  return request({
    url:"/project/profile/notice-list",
    method:'post',
    data:params
  })
}

// 新建项目公告
export function addOverviewNotice(params){
  return request({
    url:"/project/profile/notice-add",
    method:'post',
    data:params
  })
}

// 编辑项目公告
export function updateOverviewNotice(params){
  return request({
    url:"/project/profile/notice-update",
    method:'post',
    data:params
  })
}

// 删除项目公告
export function delOverviewNotice(params){
  return request({
    url:"/project/profile/notice-delete/" + params,
    method:'post',
    data:{}
  })
}

//修改计划周期
export function updatePlantime(params){
  return request({
    url:"/project/update",
    method:'post',
    data:params
  })
}